var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
/*! For license information please see CronofySchedulerEmbed.js.LICENSE.txt */
var CronofySchedulerEmbed;
(() => {
  "use strict";

  var t = {
      d: (e, n) => {
        for (var r in n) t.o(n, r) && !t.o(e, r) && Object.defineProperty(e, r, {
          enumerable: !0,
          get: n[r]
        });
      },
      o: (t, e) => Object.prototype.hasOwnProperty.call(t, e),
      r: t => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(t, "__esModule", {
          value: !0
        });
      }
    },
    e = {};
  t.r(e), t.d(e, {
    SchedulerButtonElement: () => ut,
    SchedulerCancelButtonElement: () => kt,
    SchedulerCancelDialogElement: () => Zt,
    SchedulerDialogElement: () => D
  });
  var n = "https://docs.cronofy.com/errors/embedded-scheduler/bad-token",
    r = "https://docs.cronofy.com/errors/embedded-scheduler/bad-secret-pair",
    o = "https://docs.cronofy.com/errors/embedded-scheduler/bad-scheduler-attributes",
    i = "https://docs.cronofy.com/errors/embedded-scheduler/bad-cancel-attributes";
  function a(t) {
    return "string" == typeof t || t instanceof String;
  }
  function c(t) {
    return c = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    }, c(t);
  }
  function u() {
    u = function () {
      return t;
    };
    var t = {},
      e = Object.prototype,
      n = e.hasOwnProperty,
      r = Object.defineProperty || function (t, e, n) {
        t[e] = n.value;
      },
      o = "function" == typeof Symbol ? Symbol : {},
      i = o.iterator || "@@iterator",
      a = o.asyncIterator || "@@asyncIterator",
      l = o.toStringTag || "@@toStringTag";
    function s(t, e, n) {
      return Object.defineProperty(t, e, {
        value: n,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }), t[e];
    }
    try {
      s({}, "");
    } catch (t) {
      s = function (t, e, n) {
        return t[e] = n;
      };
    }
    function f(t, e, n, o) {
      var i = e && e.prototype instanceof p ? e : p,
        a = Object.create(i.prototype),
        c = new j(o || []);
      return r(a, "_invoke", {
        value: x(t, n, c)
      }), a;
    }
    function h(t, e, n) {
      try {
        return {
          type: "normal",
          arg: t.call(e, n)
        };
      } catch (t) {
        return {
          type: "throw",
          arg: t
        };
      }
    }
    t.wrap = f;
    var d = {};
    function p() {}
    function y() {}
    function v() {}
    var g = {};
    s(g, i, function () {
      return this || _global;
    });
    var b = Object.getPrototypeOf,
      m = b && b(b(S([])));
    m && m !== e && n.call(m, i) && (g = m);
    var w = v.prototype = p.prototype = Object.create(g);
    function E(t) {
      ["next", "throw", "return"].forEach(function (e) {
        s(t, e, function (t) {
          return this._invoke(e, t);
        });
      });
    }
    function _(t, e) {
      function o(r, i, a, u) {
        var l = h(t[r], t, i);
        if ("throw" !== l.type) {
          var s = l.arg,
            f = s.value;
          return f && "object" == c(f) && n.call(f, "__await") ? e.resolve(f.__await).then(function (t) {
            o("next", t, a, u);
          }, function (t) {
            o("throw", t, a, u);
          }) : e.resolve(f).then(function (t) {
            s.value = t, a(s);
          }, function (t) {
            return o("throw", t, a, u);
          });
        }
        u(l.arg);
      }
      var i;
      r(this || _global, "_invoke", {
        value: function (t, n) {
          function r() {
            return new e(function (e, r) {
              o(t, n, e, r);
            });
          }
          return i = i ? i.then(r, r) : r();
        }
      });
    }
    function x(t, e, n) {
      var r = "suspendedStart";
      return function (o, i) {
        if ("executing" === r) throw new Error("Generator is already running");
        if ("completed" === r) {
          if ("throw" === o) throw i;
          return C();
        }
        for (n.method = o, n.arg = i;;) {
          var a = n.delegate;
          if (a) {
            var c = L(a, n);
            if (c) {
              if (c === d) continue;
              return c;
            }
          }
          if ("next" === n.method) n.sent = n._sent = n.arg;else if ("throw" === n.method) {
            if ("suspendedStart" === r) throw r = "completed", n.arg;
            n.dispatchException(n.arg);
          } else "return" === n.method && n.abrupt("return", n.arg);
          r = "executing";
          var u = h(t, e, n);
          if ("normal" === u.type) {
            if (r = n.done ? "completed" : "suspendedYield", u.arg === d) continue;
            return {
              value: u.arg,
              done: n.done
            };
          }
          "throw" === u.type && (r = "completed", n.method = "throw", n.arg = u.arg);
        }
      };
    }
    function L(t, e) {
      var n = e.method,
        r = t.iterator[n];
      if (void 0 === r) return e.delegate = null, "throw" === n && t.iterator.return && (e.method = "return", e.arg = void 0, L(t, e), "throw" === e.method) || "return" !== n && (e.method = "throw", e.arg = new TypeError("The iterator does not provide a '" + n + "' method")), d;
      var o = h(r, t.iterator, e.arg);
      if ("throw" === o.type) return e.method = "throw", e.arg = o.arg, e.delegate = null, d;
      var i = o.arg;
      return i ? i.done ? (e[t.resultName] = i.value, e.next = t.nextLoc, "return" !== e.method && (e.method = "next", e.arg = void 0), e.delegate = null, d) : i : (e.method = "throw", e.arg = new TypeError("iterator result is not an object"), e.delegate = null, d);
    }
    function O(t) {
      var e = {
        tryLoc: t[0]
      };
      1 in t && (e.catchLoc = t[1]), 2 in t && (e.finallyLoc = t[2], e.afterLoc = t[3]), (this || _global).tryEntries.push(e);
    }
    function k(t) {
      var e = t.completion || {};
      e.type = "normal", delete e.arg, t.completion = e;
    }
    function j(t) {
      (this || _global).tryEntries = [{
        tryLoc: "root"
      }], t.forEach(O, this || _global), this.reset(!0);
    }
    function S(t) {
      if (t) {
        var e = t[i];
        if (e) return e.call(t);
        if ("function" == typeof t.next) return t;
        if (!isNaN(t.length)) {
          var r = -1,
            o = function e() {
              for (; ++r < t.length;) if (n.call(t, r)) return e.value = t[r], e.done = !1, e;
              return e.value = void 0, e.done = !0, e;
            };
          return o.next = o;
        }
      }
      return {
        next: C
      };
    }
    function C() {
      return {
        value: void 0,
        done: !0
      };
    }
    return y.prototype = v, r(w, "constructor", {
      value: v,
      configurable: !0
    }), r(v, "constructor", {
      value: y,
      configurable: !0
    }), y.displayName = s(v, l, "GeneratorFunction"), t.isGeneratorFunction = function (t) {
      var e = "function" == typeof t && t.constructor;
      return !!e && (e === y || "GeneratorFunction" === (e.displayName || e.name));
    }, t.mark = function (t) {
      return Object.setPrototypeOf ? Object.setPrototypeOf(t, v) : (t.__proto__ = v, s(t, l, "GeneratorFunction")), t.prototype = Object.create(w), t;
    }, t.awrap = function (t) {
      return {
        __await: t
      };
    }, E(_.prototype), s(_.prototype, a, function () {
      return this || _global;
    }), t.AsyncIterator = _, t.async = function (e, n, r, o, i) {
      void 0 === i && (i = Promise);
      var a = new _(f(e, n, r, o), i);
      return t.isGeneratorFunction(n) ? a : a.next().then(function (t) {
        return t.done ? t.value : a.next();
      });
    }, E(w), s(w, l, "Generator"), s(w, i, function () {
      return this || _global;
    }), s(w, "toString", function () {
      return "[object Generator]";
    }), t.keys = function (t) {
      var e = Object(t),
        n = [];
      for (var r in e) n.push(r);
      return n.reverse(), function t() {
        for (; n.length;) {
          var r = n.pop();
          if (r in e) return t.value = r, t.done = !1, t;
        }
        return t.done = !0, t;
      };
    }, t.values = S, j.prototype = {
      constructor: j,
      reset: function (t) {
        if ((this || _global).prev = 0, (this || _global).next = 0, (this || _global).sent = (this || _global)._sent = void 0, (this || _global).done = !1, (this || _global).delegate = null, (this || _global).method = "next", (this || _global).arg = void 0, (this || _global).tryEntries.forEach(k), !t) for (var e in this || _global) "t" === e.charAt(0) && n.call(this || _global, e) && !isNaN(+e.slice(1)) && ((this || _global)[e] = void 0);
      },
      stop: function () {
        (this || _global).done = !0;
        var t = (this || _global).tryEntries[0].completion;
        if ("throw" === t.type) throw t.arg;
        return (this || _global).rval;
      },
      dispatchException: function (t) {
        if ((this || _global).done) throw t;
        var e = this || _global;
        function r(n, r) {
          return a.type = "throw", a.arg = t, e.next = n, r && (e.method = "next", e.arg = void 0), !!r;
        }
        for (var o = (this || _global).tryEntries.length - 1; o >= 0; --o) {
          var i = (this || _global).tryEntries[o],
            a = i.completion;
          if ("root" === i.tryLoc) return r("end");
          if (i.tryLoc <= (this || _global).prev) {
            var c = n.call(i, "catchLoc"),
              u = n.call(i, "finallyLoc");
            if (c && u) {
              if ((this || _global).prev < i.catchLoc) return r(i.catchLoc, !0);
              if ((this || _global).prev < i.finallyLoc) return r(i.finallyLoc);
            } else if (c) {
              if ((this || _global).prev < i.catchLoc) return r(i.catchLoc, !0);
            } else {
              if (!u) throw new Error("try statement without catch or finally");
              if ((this || _global).prev < i.finallyLoc) return r(i.finallyLoc);
            }
          }
        }
      },
      abrupt: function (t, e) {
        for (var r = (this || _global).tryEntries.length - 1; r >= 0; --r) {
          var o = (this || _global).tryEntries[r];
          if (o.tryLoc <= (this || _global).prev && n.call(o, "finallyLoc") && (this || _global).prev < o.finallyLoc) {
            var i = o;
            break;
          }
        }
        i && ("break" === t || "continue" === t) && i.tryLoc <= e && e <= i.finallyLoc && (i = null);
        var a = i ? i.completion : {};
        return a.type = t, a.arg = e, i ? ((this || _global).method = "next", (this || _global).next = i.finallyLoc, d) : this.complete(a);
      },
      complete: function (t, e) {
        if ("throw" === t.type) throw t.arg;
        return "break" === t.type || "continue" === t.type ? (this || _global).next = t.arg : "return" === t.type ? ((this || _global).rval = (this || _global).arg = t.arg, (this || _global).method = "return", (this || _global).next = "end") : "normal" === t.type && e && ((this || _global).next = e), d;
      },
      finish: function (t) {
        for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
          var n = (this || _global).tryEntries[e];
          if (n.finallyLoc === t) return this.complete(n.completion, n.afterLoc), k(n), d;
        }
      },
      catch: function (t) {
        for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
          var n = (this || _global).tryEntries[e];
          if (n.tryLoc === t) {
            var r = n.completion;
            if ("throw" === r.type) {
              var o = r.arg;
              k(n);
            }
            return o;
          }
        }
        throw new Error("illegal catch attempt");
      },
      delegateYield: function (t, e, n) {
        return (this || _global).delegate = {
          iterator: S(t),
          resultName: e,
          nextLoc: n
        }, "next" === (this || _global).method && ((this || _global).arg = void 0), d;
      }
    }, t;
  }
  function l(t, e, n, r, o, i, a) {
    try {
      var c = t[i](a),
        u = c.value;
    } catch (t) {
      return void n(t);
    }
    c.done ? e(u) : Promise.resolve(u).then(r, o);
  }
  function s(t) {
    var e = function (t) {
        var e = atob(t);
        return JSON.parse(e);
      },
      n = t.split(".");
    if (3 !== n.length) throw new Error("Invalid number of parts in JWT");
    return {
      header: e(n[0]),
      body: e(n[1]),
      signature: n[2]
    };
  }
  var f = new Map([["us", "app"], ["dev", "dev"], ["local", "local"]]);
  function h(t) {
    if (!t || !a(t)) throw new Error("Invalid embed token");
    var e = s(t).body.iss;
    if (!e || !a(e)) throw new Error("Invalid embed token issuer");
    var n = e.split("_");
    if (3 !== n.length) throw new Error("Invalid number of parts in embed token issuer");
    var r = n[1].toLowerCase(),
      o = f.get(r);
    return o || (o = "app-".concat(r)), "https://".concat(o, ".cronofy.com/scheduler/embedded/auth");
  }
  function d(t, e) {
    return p.apply(this || _global, arguments);
  }
  function p() {
    var t;
    return t = u().mark(function t(e, n) {
      var r, o, i;
      return u().wrap(function (t) {
        for (;;) switch (t.prev = t.next) {
          case 0:
            return r = h(e), t.next = 3, fetch(r, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer ".concat(e),
                "Cronofy-Scheduler-Embed-Version": "0.0.8",
                "Cronofy-Scheduler-Embed-Hash": "2a6c62f710fb"
              },
              body: JSON.stringify(n)
            });
          case 3:
            return o = t.sent, t.next = 6, o.json();
          case 6:
            return i = t.sent, t.abrupt("return", i.url);
          case 8:
          case "end":
            return t.stop();
        }
      }, t);
    }), p = function () {
      var e = this || _global,
        n = arguments;
      return new Promise(function (r, o) {
        var i = t.apply(e, n);
        function a(t) {
          l(i, r, o, a, c, "next", t);
        }
        function c(t) {
          l(i, r, o, a, c, "throw", t);
        }
        a(void 0);
      });
    }, p.apply(this || _global, arguments);
  }
  function y(t) {
    return y = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    }, y(t);
  }
  function v() {
    v = function () {
      return t;
    };
    var t = {},
      e = Object.prototype,
      n = e.hasOwnProperty,
      r = Object.defineProperty || function (t, e, n) {
        t[e] = n.value;
      },
      o = "function" == typeof Symbol ? Symbol : {},
      i = o.iterator || "@@iterator",
      a = o.asyncIterator || "@@asyncIterator",
      c = o.toStringTag || "@@toStringTag";
    function u(t, e, n) {
      return Object.defineProperty(t, e, {
        value: n,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }), t[e];
    }
    try {
      u({}, "");
    } catch (t) {
      u = function (t, e, n) {
        return t[e] = n;
      };
    }
    function l(t, e, n, o) {
      var i = e && e.prototype instanceof h ? e : h,
        a = Object.create(i.prototype),
        c = new j(o || []);
      return r(a, "_invoke", {
        value: x(t, n, c)
      }), a;
    }
    function s(t, e, n) {
      try {
        return {
          type: "normal",
          arg: t.call(e, n)
        };
      } catch (t) {
        return {
          type: "throw",
          arg: t
        };
      }
    }
    t.wrap = l;
    var f = {};
    function h() {}
    function d() {}
    function p() {}
    var g = {};
    u(g, i, function () {
      return this || _global;
    });
    var b = Object.getPrototypeOf,
      m = b && b(b(S([])));
    m && m !== e && n.call(m, i) && (g = m);
    var w = p.prototype = h.prototype = Object.create(g);
    function E(t) {
      ["next", "throw", "return"].forEach(function (e) {
        u(t, e, function (t) {
          return this._invoke(e, t);
        });
      });
    }
    function _(t, e) {
      function o(r, i, a, c) {
        var u = s(t[r], t, i);
        if ("throw" !== u.type) {
          var l = u.arg,
            f = l.value;
          return f && "object" == y(f) && n.call(f, "__await") ? e.resolve(f.__await).then(function (t) {
            o("next", t, a, c);
          }, function (t) {
            o("throw", t, a, c);
          }) : e.resolve(f).then(function (t) {
            l.value = t, a(l);
          }, function (t) {
            return o("throw", t, a, c);
          });
        }
        c(u.arg);
      }
      var i;
      r(this || _global, "_invoke", {
        value: function (t, n) {
          function r() {
            return new e(function (e, r) {
              o(t, n, e, r);
            });
          }
          return i = i ? i.then(r, r) : r();
        }
      });
    }
    function x(t, e, n) {
      var r = "suspendedStart";
      return function (o, i) {
        if ("executing" === r) throw new Error("Generator is already running");
        if ("completed" === r) {
          if ("throw" === o) throw i;
          return C();
        }
        for (n.method = o, n.arg = i;;) {
          var a = n.delegate;
          if (a) {
            var c = L(a, n);
            if (c) {
              if (c === f) continue;
              return c;
            }
          }
          if ("next" === n.method) n.sent = n._sent = n.arg;else if ("throw" === n.method) {
            if ("suspendedStart" === r) throw r = "completed", n.arg;
            n.dispatchException(n.arg);
          } else "return" === n.method && n.abrupt("return", n.arg);
          r = "executing";
          var u = s(t, e, n);
          if ("normal" === u.type) {
            if (r = n.done ? "completed" : "suspendedYield", u.arg === f) continue;
            return {
              value: u.arg,
              done: n.done
            };
          }
          "throw" === u.type && (r = "completed", n.method = "throw", n.arg = u.arg);
        }
      };
    }
    function L(t, e) {
      var n = e.method,
        r = t.iterator[n];
      if (void 0 === r) return e.delegate = null, "throw" === n && t.iterator.return && (e.method = "return", e.arg = void 0, L(t, e), "throw" === e.method) || "return" !== n && (e.method = "throw", e.arg = new TypeError("The iterator does not provide a '" + n + "' method")), f;
      var o = s(r, t.iterator, e.arg);
      if ("throw" === o.type) return e.method = "throw", e.arg = o.arg, e.delegate = null, f;
      var i = o.arg;
      return i ? i.done ? (e[t.resultName] = i.value, e.next = t.nextLoc, "return" !== e.method && (e.method = "next", e.arg = void 0), e.delegate = null, f) : i : (e.method = "throw", e.arg = new TypeError("iterator result is not an object"), e.delegate = null, f);
    }
    function O(t) {
      var e = {
        tryLoc: t[0]
      };
      1 in t && (e.catchLoc = t[1]), 2 in t && (e.finallyLoc = t[2], e.afterLoc = t[3]), (this || _global).tryEntries.push(e);
    }
    function k(t) {
      var e = t.completion || {};
      e.type = "normal", delete e.arg, t.completion = e;
    }
    function j(t) {
      (this || _global).tryEntries = [{
        tryLoc: "root"
      }], t.forEach(O, this || _global), this.reset(!0);
    }
    function S(t) {
      if (t) {
        var e = t[i];
        if (e) return e.call(t);
        if ("function" == typeof t.next) return t;
        if (!isNaN(t.length)) {
          var r = -1,
            o = function e() {
              for (; ++r < t.length;) if (n.call(t, r)) return e.value = t[r], e.done = !1, e;
              return e.value = void 0, e.done = !0, e;
            };
          return o.next = o;
        }
      }
      return {
        next: C
      };
    }
    function C() {
      return {
        value: void 0,
        done: !0
      };
    }
    return d.prototype = p, r(w, "constructor", {
      value: p,
      configurable: !0
    }), r(p, "constructor", {
      value: d,
      configurable: !0
    }), d.displayName = u(p, c, "GeneratorFunction"), t.isGeneratorFunction = function (t) {
      var e = "function" == typeof t && t.constructor;
      return !!e && (e === d || "GeneratorFunction" === (e.displayName || e.name));
    }, t.mark = function (t) {
      return Object.setPrototypeOf ? Object.setPrototypeOf(t, p) : (t.__proto__ = p, u(t, c, "GeneratorFunction")), t.prototype = Object.create(w), t;
    }, t.awrap = function (t) {
      return {
        __await: t
      };
    }, E(_.prototype), u(_.prototype, a, function () {
      return this || _global;
    }), t.AsyncIterator = _, t.async = function (e, n, r, o, i) {
      void 0 === i && (i = Promise);
      var a = new _(l(e, n, r, o), i);
      return t.isGeneratorFunction(n) ? a : a.next().then(function (t) {
        return t.done ? t.value : a.next();
      });
    }, E(w), u(w, c, "Generator"), u(w, i, function () {
      return this || _global;
    }), u(w, "toString", function () {
      return "[object Generator]";
    }), t.keys = function (t) {
      var e = Object(t),
        n = [];
      for (var r in e) n.push(r);
      return n.reverse(), function t() {
        for (; n.length;) {
          var r = n.pop();
          if (r in e) return t.value = r, t.done = !1, t;
        }
        return t.done = !0, t;
      };
    }, t.values = S, j.prototype = {
      constructor: j,
      reset: function (t) {
        if ((this || _global).prev = 0, (this || _global).next = 0, (this || _global).sent = (this || _global)._sent = void 0, (this || _global).done = !1, (this || _global).delegate = null, (this || _global).method = "next", (this || _global).arg = void 0, (this || _global).tryEntries.forEach(k), !t) for (var e in this || _global) "t" === e.charAt(0) && n.call(this || _global, e) && !isNaN(+e.slice(1)) && ((this || _global)[e] = void 0);
      },
      stop: function () {
        (this || _global).done = !0;
        var t = (this || _global).tryEntries[0].completion;
        if ("throw" === t.type) throw t.arg;
        return (this || _global).rval;
      },
      dispatchException: function (t) {
        if ((this || _global).done) throw t;
        var e = this || _global;
        function r(n, r) {
          return a.type = "throw", a.arg = t, e.next = n, r && (e.method = "next", e.arg = void 0), !!r;
        }
        for (var o = (this || _global).tryEntries.length - 1; o >= 0; --o) {
          var i = (this || _global).tryEntries[o],
            a = i.completion;
          if ("root" === i.tryLoc) return r("end");
          if (i.tryLoc <= (this || _global).prev) {
            var c = n.call(i, "catchLoc"),
              u = n.call(i, "finallyLoc");
            if (c && u) {
              if ((this || _global).prev < i.catchLoc) return r(i.catchLoc, !0);
              if ((this || _global).prev < i.finallyLoc) return r(i.finallyLoc);
            } else if (c) {
              if ((this || _global).prev < i.catchLoc) return r(i.catchLoc, !0);
            } else {
              if (!u) throw new Error("try statement without catch or finally");
              if ((this || _global).prev < i.finallyLoc) return r(i.finallyLoc);
            }
          }
        }
      },
      abrupt: function (t, e) {
        for (var r = (this || _global).tryEntries.length - 1; r >= 0; --r) {
          var o = (this || _global).tryEntries[r];
          if (o.tryLoc <= (this || _global).prev && n.call(o, "finallyLoc") && (this || _global).prev < o.finallyLoc) {
            var i = o;
            break;
          }
        }
        i && ("break" === t || "continue" === t) && i.tryLoc <= e && e <= i.finallyLoc && (i = null);
        var a = i ? i.completion : {};
        return a.type = t, a.arg = e, i ? ((this || _global).method = "next", (this || _global).next = i.finallyLoc, f) : this.complete(a);
      },
      complete: function (t, e) {
        if ("throw" === t.type) throw t.arg;
        return "break" === t.type || "continue" === t.type ? (this || _global).next = t.arg : "return" === t.type ? ((this || _global).rval = (this || _global).arg = t.arg, (this || _global).method = "return", (this || _global).next = "end") : "normal" === t.type && e && ((this || _global).next = e), f;
      },
      finish: function (t) {
        for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
          var n = (this || _global).tryEntries[e];
          if (n.finallyLoc === t) return this.complete(n.completion, n.afterLoc), k(n), f;
        }
      },
      catch: function (t) {
        for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
          var n = (this || _global).tryEntries[e];
          if (n.tryLoc === t) {
            var r = n.completion;
            if ("throw" === r.type) {
              var o = r.arg;
              k(n);
            }
            return o;
          }
        }
        throw new Error("illegal catch attempt");
      },
      delegateYield: function (t, e, n) {
        return (this || _global).delegate = {
          iterator: S(t),
          resultName: e,
          nextLoc: n
        }, "next" === (this || _global).method && ((this || _global).arg = void 0), f;
      }
    }, t;
  }
  function g(t, e, n, r, o, i, a) {
    try {
      var c = t[i](a),
        u = c.value;
    } catch (t) {
      return void n(t);
    }
    c.done ? e(u) : Promise.resolve(u).then(r, o);
  }
  function b(t) {
    return function () {
      var e = this || _global,
        n = arguments;
      return new Promise(function (r, o) {
        var i = t.apply(e, n);
        function a(t) {
          g(i, r, o, a, c, "next", t);
        }
        function c(t) {
          g(i, r, o, a, c, "throw", t);
        }
        a(void 0);
      });
    };
  }
  function m(t, e) {
    for (var n = 0; n < e.length; n++) {
      var r = e[n];
      r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(t, (o = r.key, i = void 0, i = function (t, e) {
        if ("object" !== y(t) || null === t) return t;
        var n = t[Symbol.toPrimitive];
        if (void 0 !== n) {
          var r = n.call(t, e || "default");
          if ("object" !== y(r)) return r;
          throw new TypeError("@@toPrimitive must return a primitive value.");
        }
        return ("string" === e ? String : Number)(t);
      }(o, "string"), "symbol" === y(i) ? i : String(i)), r);
    }
    var o, i;
  }
  function w(t) {
    var e = L();
    return function () {
      var n,
        r = k(t);
      if (e) {
        var o = k(this || _global).constructor;
        n = Reflect.construct(r, arguments, o);
      } else n = r.apply(this || _global, arguments);
      return function (t, e) {
        if (e && ("object" === y(e) || "function" == typeof e)) return e;
        if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined");
        return E(t);
      }(this || _global, n);
    };
  }
  function E(t) {
    if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return t;
  }
  function _(t) {
    var e = "function" == typeof Map ? new Map() : void 0;
    return _ = function (t) {
      if (null === t || (n = t, -1 === Function.toString.call(n).indexOf("[native code]"))) return t;
      var n;
      if ("function" != typeof t) throw new TypeError("Super expression must either be null or a function");
      if (void 0 !== e) {
        if (e.has(t)) return e.get(t);
        e.set(t, r);
      }
      function r() {
        return x(t, arguments, k(this || _global).constructor);
      }
      return r.prototype = Object.create(t.prototype, {
        constructor: {
          value: r,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), O(r, t);
    }, _(t);
  }
  function x(t, e, n) {
    return x = L() ? Reflect.construct.bind() : function (t, e, n) {
      var r = [null];
      r.push.apply(r, e);
      var o = new (Function.bind.apply(t, r))();
      return n && O(o, n.prototype), o;
    }, x.apply(null, arguments);
  }
  function L() {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
    } catch (t) {
      return !1;
    }
  }
  function O(t, e) {
    return O = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (t, e) {
      return t.__proto__ = e, t;
    }, O(t, e);
  }
  function k(t) {
    return k = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (t) {
      return t.__proto__ || Object.getPrototypeOf(t);
    }, k(t);
  }
  function j(t, e) {
    S(t, e), e.add(t);
  }
  function S(t, e) {
    if (e.has(t)) throw new TypeError("Cannot initialize the same private elements twice on an object");
  }
  function C(t, e) {
    var n = function (t, e, n) {
      if (!e.has(t)) throw new TypeError("attempted to " + n + " private field on non-instance");
      return e.get(t);
    }(t, e, "get");
    return function (t, e) {
      if (e.get) return e.get.call(t);
      return e.value;
    }(t, n);
  }
  function P(t, e, n) {
    if (!e.has(t)) throw new TypeError("attempted to get private field on non-instance");
    return n;
  }
  var T = new WeakSet(),
    A = new WeakMap(),
    M = new WeakSet(),
    D = function (t) {
      !function (t, e) {
        if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
        t.prototype = Object.create(e && e.prototype, {
          constructor: {
            value: t,
            writable: !0,
            configurable: !0
          }
        }), Object.defineProperty(t, "prototype", {
          writable: !1
        }), e && O(t, e);
      }(c, t);
      var e,
        n,
        r,
        o,
        i,
        a = w(c);
      function c() {
        var t, e, n, r;
        !function (t, e) {
          if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, c);
        for (var o = arguments.length, i = new Array(o), u = 0; u < o; u++) i[u] = arguments[u];
        return j(E(t = a.call.apply(a, [this || _global].concat(i))), M), j(E(t), T), e = E(t), r = {
          writable: !0,
          value: function (e) {
            switch (e.data.type) {
              case "scheduler-ready":
                var n = t._schedulerDialog.querySelector("iframe");
                n && n.contentWindow && n.contentWindow.postMessage({
                  type: "initialize-scheduler",
                  defaults: P(E(t), M, R).call(E(t))
                }, "*");
                break;
              case "scheduler-link-created":
                t.dispatchEvent(new CustomEvent("cronofyschedulerrequestcreated", {
                  detail: e.data.content
                })), t.close();
                break;
              case "scheduler-error":
                t.dispatchEvent(new CustomEvent("cronofyschedulererror", {
                  detail: e.data.content
                }));
            }
          }
        }, S(e, n = A), n.set(e, r), t;
      }
      return e = c, n = [{
        key: "open",
        get: function () {
          return (this || _global)._schedulerDialog.open;
        }
      }, {
        key: "connectedCallback",
        value: (i = b(v().mark(function t() {
          var e,
            n = this || _global;
          return v().wrap(function (t) {
            for (;;) switch (t.prev = t.next) {
              case 0:
                (e = this.attachShadow({
                  mode: "closed"
                })).innerHTML = "\n            <style>\n                dialog {\n                    display: flex;\n                    flex-direction: column;\n                    width: 450px;\n                    height: 450px;\n                }\n\n                dialog:modal {\n                    border: none;\n                    border-radius: 6px 6px 6px 6px;\n                    padding: 0;\n                }\n\n                .content {\n                    flex-grow: 1;\n                    border: none;\n                    margin: 0;\n                    padding: 0;\n                }\n\n                .centered-content {\n                    display: flex;\n                    flex-grow: 1;\n                    flex-direction: column;\n                    margin: 0;\n                    padding: 0;\n                    justify-content: center;\n                    align-items: center;\n                }\n            </style>\n\n            <dialog part=\"dialog\"></dialog>\n        ", (this || _global)._schedulerDialog = e.querySelector("dialog"), (this || _global)._schedulerDialog.addEventListener("click", function (t) {
                  if (t.target === n._schedulerDialog) {
                    var e = n._schedulerDialog.getBoundingClientRect();
                    e.top <= t.clientY && t.clientY <= e.top + e.height && e.left <= t.clientX && t.clientX <= e.left + e.width || n.close();
                  }
                }), (this || _global)._schedulerDialog.addEventListener("close", function (t) {
                  P(n, T, N).call(n);
                });
              case 5:
              case "end":
                return t.stop();
            }
          }, t, this || _global);
        })), function () {
          return i.apply(this || _global, arguments);
        })
      }, {
        key: "disconnectedCallback",
        value: function () {
          this.close();
        }
      }, {
        key: "show",
        value: (o = b(v().mark(function t() {
          var e,
            n,
            r,
            o = this || _global;
          return v().wrap(function (t) {
            for (;;) switch (t.prev = t.next) {
              case 0:
                return (this || _global)._schedulerDialog.innerHTML = "\n            <div class=\"centered-content\">\n                <p>\n                    Loading...\n                </p>\n            </div>\n        ", (this || _global)._schedulerDialog.showModal(), this.dispatchEvent(new Event("cronofyschedulerdialogopened")), t.prev = 3, e = this.getAttribute("embed-token"), n = this.getAttribute("correlation-id"), t.next = 8, d(e, {
                  embed_action: "create",
                  correlation_id: n
                });
              case 8:
                r = t.sent, (this || _global)._schedulerDialog.innerHTML = "\n                <iframe\n                    class=\"content\"\n                    src=\"".concat(r, "\"\n                    title=\"Cronofy Embedded Scheduler\"\n                ></iframe>\n            "), window.addEventListener("message", C(this || _global, A)), t.next = 20;
                break;
              case 13:
                t.prev = 13, t.t0 = t.catch(3), console.error("An unexpected error occurred while trying to show the scheduler dialog.\n", t.t0, this || _global), (this || _global)._schedulerDialog.innerHTML = "\n                <div class=\"centered-content\">\n                    <p>\n                        An error occurred.\n                        See console for details.\n                    </p>\n                    <button>Close</button>\n                </div>\n            ", (this || _global)._schedulerDialog.querySelector("div > button").addEventListener("click", function (t) {
                  o.close();
                }), this.dispatchEvent(new CustomEvent("cronofyschedulererror", {
                  detail: {
                    code: "unknown",
                    exception: t.t0
                  }
                }));
              case 20:
              case "end":
                return t.stop();
            }
          }, t, this || _global, [[3, 13]]);
        })), function () {
          return o.apply(this || _global, arguments);
        })
      }, {
        key: "close",
        value: function () {
          (this || _global)._schedulerDialog.open && (this || _global)._schedulerDialog.close();
        }
      }], n && m(e.prototype, n), r && m(e, r), Object.defineProperty(e, "prototype", {
        writable: !1
      }), c;
    }(_(HTMLElement));
  function N() {
    window.removeEventListener("message", C(this || _global, A)), this.dispatchEvent(new Event("cronofyschedulerdialogclosed"));
  }
  function R() {
    var t = {},
      e = this.getAttribute("recipient-email"),
      n = this.getAttribute("recipient-name"),
      r = this.getAttribute("recipient-organization-name");
    e || !n && !r ? (t.recipient = {
      email: e
    }, null != n && (t.recipient.name = n), null != r && (t.recipient.organizationName = r)) : console.error(["The recipient attributes are not valid.", "", "In order to specify a default recipient you must set the 'recipient-email' attribute. Because this attribute is missing no default recipient will be selected.", "", o].join("\n"), this || _global);
    var i = this.getAttribute("event-summary");
    i && (t.name = i);
    var a = this.getAttribute("event-duration-minutes");
    if (a) {
      var c = parseFloat(a);
      isNaN(c) || !isFinite(c) || c <= 0 || c % 1 != 0 || c.toString() != a ? console.error(["Invalid value for event-duration-minutes.", "", "The 'event-duration-minutes' attribute is not a positive integer. The users default event duration will be used instead.", "", o].join("\n"), this || _global) : t.duration = {
        minutes: a
      };
    }
    return t;
  }
  var q = "white",
    B = "#161A1E",
    F = "#FF0044",
    H = "transparent",
    G = "currentColor",
    I = "currentColor",
    W = "cronofy-scheduler-embed-button_logo-body",
    Y = "cronofy-scheduler-embed-button_logo-outline",
    V = "cronofy-scheduler-embed-button_logo-header";
  const z = "\n    <style>\n        svg { height: 1.2em; margin-bottom: -0.2em; }\n        svg path.".concat(W, " { fill: ").concat(H, "; }\n        :hover:enabled svg path.").concat(W, " { fill: ").concat(q, "; }\n        svg path.").concat(Y, " { fill: ").concat(G, "; }\n        :hover:enabled svg path.").concat(Y, " { fill: ").concat(B, "; }\n        svg path.").concat(V, " { fill: ").concat(I, "; }\n        :hover:enabled svg path.").concat(V, " { fill: ").concat(F, "; }\n    </style>\n    <svg class=\"branding__logo\" aria-hidden=\"true\" viewBox=\"0 0 190 230\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n        <path class=\"").concat(W, "\" d=\"M8.71899 8.71898H147.133C165.792 8.71898 180.919 23.8455 180.919 42.505V221.244H85.0101C42.8757 221.244 8.71899 187.087 8.71899 144.953V8.71898Z\"></path>\n        <path class=\"").concat(Y, "\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0H147.133C170.608 0 189.638 19.0301 189.638 42.505V229.963H85.0101C38.0603 229.963 0 191.903 0 144.953V0ZM8.71898 8.71898V144.953C8.71898 187.087 42.8757 221.244 85.0101 221.244H180.919V42.505C180.919 23.8455 165.792 8.71898 147.133 8.71898H8.71898Z\"></path>\n        <path class=\"").concat(W, "\" d=\"M8.71899 42.505H180.919V221.244H85.0101C42.8757 221.244 8.71899 187.087 8.71899 144.953V42.505Z\"></path>\n        <path class=\"").concat(V, "\" d=\"M8.71899 8.71898H147.133C165.792 8.71898 180.919 23.8455 180.919 42.505V42.505H8.71899V8.71898Z\"></path>\n        <path class=\"").concat(Y, "\" d=\"M97.7537 187.109C123.082 187.109 143.071 172.919 149.174 150.49L126.592 142.86C123.54 155.677 111.944 164.222 97.7537 164.222C78.8336 164.222 65.5589 150.795 65.5589 131.875C65.5589 112.802 78.9861 99.5271 97.7537 99.5271C111.486 99.5271 122.625 107.309 125.829 119.668L147.953 111.734C141.85 90.2196 122.625 76.6398 97.7537 76.6398C65.4063 76.6398 41.6035 100.137 41.6035 131.875C41.6035 163.612 65.4063 187.109 97.7537 187.109Z\"></path>\n    </svg>\n");
  function Z(t) {
    if (null == t || "" === t) return ["The 'embed-token' attribute is missing.", "", "In order to authenticate with Cronofy and generate scheduler requests you must first generate an embed token. You can find how to generate the embed token on the docs.", "", n].join("\n");
    if ("{EMBED_TOKEN}" === t) return ["The 'embed-token' attribute is not valid.", "", "It looks like you have copied the example usage directly. In order to authenticate with Cronofy and generate scheduler requests you must first generate an embed token. You can find how to generate the embed token on the docs.", "", n].join("\n");
    var e;
    try {
      e = s(t);
    } catch (t) {
      return ["The 'embed-token' attribute is not valid.", "", "It may have been generated incorrectly, have missing fields, or have additional whitespace surrounding it. You can find examples of generating the embed token on the docs.", "", n].join("\n");
    }
    var o = e.body.iss;
    return o && a(o) && o.startsWith("EMB_") ? void 0 : ["The 'embed-token' attribute is not valid.", "", "The `iss` claim should be your embed public key, which begins EMB_. You can find how to generate the embed token, and the public key/secret pairs on the docs.", "", r].join("\n");
  }
  function X(t) {
    return X = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    }, X(t);
  }
  function J(t, e) {
    for (var n = 0; n < e.length; n++) {
      var r = e[n];
      r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(t, (o = r.key, i = void 0, i = function (t, e) {
        if ("object" !== X(t) || null === t) return t;
        var n = t[Symbol.toPrimitive];
        if (void 0 !== n) {
          var r = n.call(t, e || "default");
          if ("object" !== X(r)) return r;
          throw new TypeError("@@toPrimitive must return a primitive value.");
        }
        return ("string" === e ? String : Number)(t);
      }(o, "string"), "symbol" === X(i) ? i : String(i)), r);
    }
    var o, i;
  }
  function K(t) {
    var e = tt();
    return function () {
      var n,
        r = nt(t);
      if (e) {
        var o = nt(this || _global).constructor;
        n = Reflect.construct(r, arguments, o);
      } else n = r.apply(this || _global, arguments);
      return function (t, e) {
        if (e && ("object" === X(e) || "function" == typeof e)) return e;
        if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined");
        return Q(t);
      }(this || _global, n);
    };
  }
  function Q(t) {
    if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return t;
  }
  function U(t) {
    var e = "function" == typeof Map ? new Map() : void 0;
    return U = function (t) {
      if (null === t || (n = t, -1 === Function.toString.call(n).indexOf("[native code]"))) return t;
      var n;
      if ("function" != typeof t) throw new TypeError("Super expression must either be null or a function");
      if (void 0 !== e) {
        if (e.has(t)) return e.get(t);
        e.set(t, r);
      }
      function r() {
        return $(t, arguments, nt(this || _global).constructor);
      }
      return r.prototype = Object.create(t.prototype, {
        constructor: {
          value: r,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), et(r, t);
    }, U(t);
  }
  function $(t, e, n) {
    return $ = tt() ? Reflect.construct.bind() : function (t, e, n) {
      var r = [null];
      r.push.apply(r, e);
      var o = new (Function.bind.apply(t, r))();
      return n && et(o, n.prototype), o;
    }, $.apply(null, arguments);
  }
  function tt() {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
    } catch (t) {
      return !1;
    }
  }
  function et(t, e) {
    return et = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (t, e) {
      return t.__proto__ = e, t;
    }, et(t, e);
  }
  function nt(t) {
    return nt = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (t) {
      return t.__proto__ || Object.getPrototypeOf(t);
    }, nt(t);
  }
  function rt(t, e) {
    !function (t, e) {
      if (e.has(t)) throw new TypeError("Cannot initialize the same private elements twice on an object");
    }(t, e), e.add(t);
  }
  function ot(t, e, n) {
    if (!e.has(t)) throw new TypeError("attempted to get private field on non-instance");
    return n;
  }
  var it = new WeakSet(),
    at = new WeakSet(),
    ct = new WeakSet(),
    ut = function (t) {
      !function (t, e) {
        if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
        t.prototype = Object.create(e && e.prototype, {
          constructor: {
            value: t,
            writable: !0,
            configurable: !0
          }
        }), Object.defineProperty(t, "prototype", {
          writable: !1
        }), e && et(t, e);
      }(i, t);
      var e,
        n,
        r,
        o = K(i);
      function i() {
        var t;
        !function (t, e) {
          if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, i);
        for (var e = arguments.length, n = new Array(e), r = 0; r < e; r++) n[r] = arguments[r];
        return rt(Q(t = o.call.apply(o, [this || _global].concat(n))), ct), rt(Q(t), at), rt(Q(t), it), t;
      }
      return e = i, r = [{
        key: "observedAttributes",
        get: function () {
          return ["embed-token"];
        }
      }], (n = [{
        key: "open",
        get: function () {
          return null != (this || _global)._dialog && (this || _global)._dialog.open;
        }
      }, {
        key: "connectedCallback",
        value: function () {
          var t = this || _global;
          (this || _global)._isConnected = !1, (this || _global)._shadow = this.attachShadow({
            mode: "closed"
          }), (this || _global)._shadow.innerHTML = "\n            <style>\n                button:hover:enabled {\n                    cursor: pointer;\n                }\n\n                button:hover:disabled {\n                    cursor: not-allowed;\n                }\n            </style>\n\n            <button\n                part=\"button\"\n                name=\"Cronofy Scheduler\"\n                disabled\n            >\n                ".concat(z, "\n                ").concat("Schedule", "\n            </button>\n        "), (this || _global)._schedulerButton = (this || _global)._shadow.querySelector("button"), this.hasAttribute("disabled") ? (this || _global)._schedulerButton.setAttribute("disabled", "") : ot(this || _global, it, lt).call(this || _global), (this || _global)._schedulerButton.addEventListener("click", function (e) {
            t.show();
          }), (this || _global)._isConnected = !0;
        }
      }, {
        key: "disconnectedCallback",
        value: function () {
          (this || _global)._isConnected = !1;
        }
      }, {
        key: "attributeChangedCallback",
        value: function (t, e, n) {
          var r;
          (this || _global)._isConnected && (this.hasAttribute("disabled") ? null === (r = (this || _global)._schedulerButton) || void 0 === r || r.setAttribute("disabled", "") : ot(this || _global, it, lt).call(this || _global));
        }
      }, {
        key: "show",
        value: function () {
          var t = this || _global;
          (this || _global)._dialog || ((this || _global)._dialog = document.createElement("cronofy-scheduler-dialog"), (this || _global)._dialog.setAttribute("exportparts", "dialog"), ot(this || _global, at, st).call(this || _global, (this || _global)._dialog), ot(this || _global, ct, ft).call(this || _global, (this || _global)._dialog), (this || _global)._dialog.addEventListener("cronofyschedulerdialogclosed", function (e) {
            t._dialog.remove(), t._dialog = null;
          }), (this || _global)._shadow.appendChild((this || _global)._dialog), (this || _global)._dialog.show());
        }
      }, {
        key: "close",
        value: function () {
          (this || _global)._dialog && (this || _global)._dialog.close();
        }
      }]) && J(e.prototype, n), r && J(e, r), Object.defineProperty(e, "prototype", {
        writable: !1
      }), i;
    }(U(HTMLElement));
  function lt() {
    var t,
      e,
      n = Z(this.getAttribute("embed-token"));
    n ? (null === (t = (this || _global)._schedulerButton) || void 0 === t || t.setAttribute("disabled", ""), console.error(n, this || _global)) : null === (e = (this || _global)._schedulerButton) || void 0 === e || e.removeAttribute("disabled");
  }
  function st(t) {
    var e = this || _global,
      n = function (n) {
        var r = e.getAttribute(n);
        null == r ? t.removeAttribute(n) : t.setAttribute(n, r);
      };
    n("recipient-email"), n("recipient-name"), n("recipient-organization-name"), n("event-summary"), n("event-duration-minutes"), n("embed-token"), n("correlation-id");
  }
  function ft(t) {
    var e = this || _global;
    t.addEventListener("cronofyschedulerrequestcreated", function (t) {
      e.dispatchEvent(new CustomEvent("cronofyschedulerrequestcreated", {
        detail: t.detail
      }));
    }), t.addEventListener("cronofyschedulerdialogopened", function (t) {
      e.dispatchEvent(new Event("cronofyschedulerdialogopened"));
    }), t.addEventListener("cronofyschedulerdialogclosed", function (t) {
      e.dispatchEvent(new Event("cronofyschedulerdialogclosed"));
    }), t.addEventListener("cronofyschedulererror", function (t) {
      e.dispatchEvent(new CustomEvent("cronofyschedulererror", {
        detail: t.detail
      }));
    });
  }
  function ht(t) {
    return ht = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    }, ht(t);
  }
  function dt(t, e) {
    for (var n = 0; n < e.length; n++) {
      var r = e[n];
      r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(t, (o = r.key, i = void 0, i = function (t, e) {
        if ("object" !== ht(t) || null === t) return t;
        var n = t[Symbol.toPrimitive];
        if (void 0 !== n) {
          var r = n.call(t, e || "default");
          if ("object" !== ht(r)) return r;
          throw new TypeError("@@toPrimitive must return a primitive value.");
        }
        return ("string" === e ? String : Number)(t);
      }(o, "string"), "symbol" === ht(i) ? i : String(i)), r);
    }
    var o, i;
  }
  function pt(t) {
    var e = bt();
    return function () {
      var n,
        r = wt(t);
      if (e) {
        var o = wt(this || _global).constructor;
        n = Reflect.construct(r, arguments, o);
      } else n = r.apply(this || _global, arguments);
      return function (t, e) {
        if (e && ("object" === ht(e) || "function" == typeof e)) return e;
        if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined");
        return yt(t);
      }(this || _global, n);
    };
  }
  function yt(t) {
    if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return t;
  }
  function vt(t) {
    var e = "function" == typeof Map ? new Map() : void 0;
    return vt = function (t) {
      if (null === t || (n = t, -1 === Function.toString.call(n).indexOf("[native code]"))) return t;
      var n;
      if ("function" != typeof t) throw new TypeError("Super expression must either be null or a function");
      if (void 0 !== e) {
        if (e.has(t)) return e.get(t);
        e.set(t, r);
      }
      function r() {
        return gt(t, arguments, wt(this || _global).constructor);
      }
      return r.prototype = Object.create(t.prototype, {
        constructor: {
          value: r,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), mt(r, t);
    }, vt(t);
  }
  function gt(t, e, n) {
    return gt = bt() ? Reflect.construct.bind() : function (t, e, n) {
      var r = [null];
      r.push.apply(r, e);
      var o = new (Function.bind.apply(t, r))();
      return n && mt(o, n.prototype), o;
    }, gt.apply(null, arguments);
  }
  function bt() {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
    } catch (t) {
      return !1;
    }
  }
  function mt(t, e) {
    return mt = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (t, e) {
      return t.__proto__ = e, t;
    }, mt(t, e);
  }
  function wt(t) {
    return wt = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (t) {
      return t.__proto__ || Object.getPrototypeOf(t);
    }, wt(t);
  }
  function Et(t, e) {
    !function (t, e) {
      if (e.has(t)) throw new TypeError("Cannot initialize the same private elements twice on an object");
    }(t, e), e.add(t);
  }
  function _t(t, e, n) {
    if (!e.has(t)) throw new TypeError("attempted to get private field on non-instance");
    return n;
  }
  var xt = new WeakSet(),
    Lt = new WeakSet(),
    Ot = new WeakSet(),
    kt = function (t) {
      !function (t, e) {
        if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
        t.prototype = Object.create(e && e.prototype, {
          constructor: {
            value: t,
            writable: !0,
            configurable: !0
          }
        }), Object.defineProperty(t, "prototype", {
          writable: !1
        }), e && mt(t, e);
      }(i, t);
      var e,
        n,
        r,
        o = pt(i);
      function i() {
        var t;
        !function (t, e) {
          if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, i);
        for (var e = arguments.length, n = new Array(e), r = 0; r < e; r++) n[r] = arguments[r];
        return Et(yt(t = o.call.apply(o, [this || _global].concat(n))), Ot), Et(yt(t), Lt), Et(yt(t), xt), t;
      }
      return e = i, r = [{
        key: "observedAttributes",
        get: function () {
          return ["embed-token", "scheduling-request-id"];
        }
      }], (n = [{
        key: "open",
        get: function () {
          return null != (this || _global)._dialog && (this || _global)._dialog.open;
        }
      }, {
        key: "connectedCallback",
        value: function () {
          var t = this || _global;
          (this || _global)._isConnected = !1, (this || _global)._shadow = this.attachShadow({
            mode: "closed"
          }), (this || _global)._shadow.innerHTML = "\n            <style>\n                button:hover:enabled {\n                    cursor: pointer;\n                }\n\n                button:hover:disabled {\n                    cursor: not-allowed;\n                }\n            </style>\n\n            <button\n                part=\"button\"\n                name=\"Cancel Scheduling Request\"\n                disabled\n            >\n                ".concat(z, "\n                ").concat("Cancel", "\n            </button>\n        "), (this || _global)._cancelButton = (this || _global)._shadow.querySelector("button"), this.hasAttribute("disabled") ? (this || _global)._cancelButton.setAttribute("disabled", "") : _t(this || _global, xt, jt).call(this || _global), (this || _global)._cancelButton.addEventListener("click", function (e) {
            t.show();
          }), (this || _global)._isConnected = !0;
        }
      }, {
        key: "disconnectedCallback",
        value: function () {
          (this || _global)._isConnected = !1;
        }
      }, {
        key: "attributeChangedCallback",
        value: function (t, e, n) {
          var r;
          (this || _global)._isConnected && (this.hasAttribute("disabled") ? null === (r = (this || _global)._cancelButton) || void 0 === r || r.setAttribute("disabled", "") : _t(this || _global, xt, jt).call(this || _global));
        }
      }, {
        key: "show",
        value: function () {
          var t = this || _global;
          (this || _global)._dialog || ((this || _global)._dialog = document.createElement("cronofy-scheduler-cancel-dialog"), (this || _global)._dialog.setAttribute("exportparts", "dialog"), _t(this || _global, Lt, St).call(this || _global, (this || _global)._dialog), _t(this || _global, Ot, Ct).call(this || _global, (this || _global)._dialog), (this || _global)._dialog.addEventListener("cronofyschedulerdialogclosed", function (e) {
            t._dialog.remove(), t._dialog = null;
          }), (this || _global)._shadow.appendChild((this || _global)._dialog), (this || _global)._dialog.show());
        }
      }, {
        key: "close",
        value: function () {
          (this || _global)._dialog && (this || _global)._dialog.close();
        }
      }]) && dt(e.prototype, n), r && dt(e, r), Object.defineProperty(e, "prototype", {
        writable: !1
      }), i;
    }(vt(HTMLElement));
  function jt() {
    var t = !0,
      e = Z(this.getAttribute("embed-token"));
    e && (console.error(e, this || _global), t = !1);
    var n,
      r,
      o = function (t) {
        return null == t || "" === t ? ["The 'scheduling-request-id' attribute is missing.", "", "In order to cancel a Scheduling Request, 'scheduling-request-id' must be provided. Please set the value to the 'scheduling-request-id' attribute of the cancel button. You can get the ID from push notification. For details, please reference to the docs.", "", i].join("\n") : t.startsWith("srq_") && 28 === t.length ? void 0 : ["The 'scheduling-request-id' attribute is not valid.", "", "The format of 'scheduling-request-id' is incorrect. It should start with 'srq_' followed by a string with 24 characters long. You can get the ID from push notification. For details, please reference to the docs.", "", i].join("\n");
      }(this.getAttribute("scheduling-request-id"));
    (o && (console.error(o, this || _global), t = !1), t) ? null === (n = (this || _global)._cancelButton) || void 0 === n || n.removeAttribute("disabled") : null === (r = (this || _global)._cancelButton) || void 0 === r || r.setAttribute("disabled", "");
  }
  function St(t) {
    var e = this || _global,
      n = function (n) {
        var r = e.getAttribute(n);
        null == r ? t.removeAttribute(n) : t.setAttribute(n, r);
      };
    n("embed-token"), n("scheduling-request-id");
  }
  function Ct(t) {
    var e = this || _global;
    t.addEventListener("cronofyschedulerrequestcancelled", function (t) {
      e.dispatchEvent(new CustomEvent("cronofyschedulerrequestcancelled", {
        detail: t.detail
      }));
    }), t.addEventListener("cronofyschedulerdialogopened", function (t) {
      e.dispatchEvent(new Event("cronofyschedulerdialogopened"));
    }), t.addEventListener("cronofyschedulerdialogclosed", function (t) {
      e.dispatchEvent(new Event("cronofyschedulerdialogclosed"));
    }), t.addEventListener("cronofyschedulererror", function (t) {
      e.dispatchEvent(new CustomEvent("cronofyschedulererror", {
        detail: t.detail
      }));
    });
  }
  function Pt(t) {
    return Pt = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    }, Pt(t);
  }
  function Tt() {
    Tt = function () {
      return t;
    };
    var t = {},
      e = Object.prototype,
      n = e.hasOwnProperty,
      r = Object.defineProperty || function (t, e, n) {
        t[e] = n.value;
      },
      o = "function" == typeof Symbol ? Symbol : {},
      i = o.iterator || "@@iterator",
      a = o.asyncIterator || "@@asyncIterator",
      c = o.toStringTag || "@@toStringTag";
    function u(t, e, n) {
      return Object.defineProperty(t, e, {
        value: n,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }), t[e];
    }
    try {
      u({}, "");
    } catch (t) {
      u = function (t, e, n) {
        return t[e] = n;
      };
    }
    function l(t, e, n, o) {
      var i = e && e.prototype instanceof h ? e : h,
        a = Object.create(i.prototype),
        c = new O(o || []);
      return r(a, "_invoke", {
        value: E(t, n, c)
      }), a;
    }
    function s(t, e, n) {
      try {
        return {
          type: "normal",
          arg: t.call(e, n)
        };
      } catch (t) {
        return {
          type: "throw",
          arg: t
        };
      }
    }
    t.wrap = l;
    var f = {};
    function h() {}
    function d() {}
    function p() {}
    var y = {};
    u(y, i, function () {
      return this || _global;
    });
    var v = Object.getPrototypeOf,
      g = v && v(v(k([])));
    g && g !== e && n.call(g, i) && (y = g);
    var b = p.prototype = h.prototype = Object.create(y);
    function m(t) {
      ["next", "throw", "return"].forEach(function (e) {
        u(t, e, function (t) {
          return this._invoke(e, t);
        });
      });
    }
    function w(t, e) {
      function o(r, i, a, c) {
        var u = s(t[r], t, i);
        if ("throw" !== u.type) {
          var l = u.arg,
            f = l.value;
          return f && "object" == Pt(f) && n.call(f, "__await") ? e.resolve(f.__await).then(function (t) {
            o("next", t, a, c);
          }, function (t) {
            o("throw", t, a, c);
          }) : e.resolve(f).then(function (t) {
            l.value = t, a(l);
          }, function (t) {
            return o("throw", t, a, c);
          });
        }
        c(u.arg);
      }
      var i;
      r(this || _global, "_invoke", {
        value: function (t, n) {
          function r() {
            return new e(function (e, r) {
              o(t, n, e, r);
            });
          }
          return i = i ? i.then(r, r) : r();
        }
      });
    }
    function E(t, e, n) {
      var r = "suspendedStart";
      return function (o, i) {
        if ("executing" === r) throw new Error("Generator is already running");
        if ("completed" === r) {
          if ("throw" === o) throw i;
          return j();
        }
        for (n.method = o, n.arg = i;;) {
          var a = n.delegate;
          if (a) {
            var c = _(a, n);
            if (c) {
              if (c === f) continue;
              return c;
            }
          }
          if ("next" === n.method) n.sent = n._sent = n.arg;else if ("throw" === n.method) {
            if ("suspendedStart" === r) throw r = "completed", n.arg;
            n.dispatchException(n.arg);
          } else "return" === n.method && n.abrupt("return", n.arg);
          r = "executing";
          var u = s(t, e, n);
          if ("normal" === u.type) {
            if (r = n.done ? "completed" : "suspendedYield", u.arg === f) continue;
            return {
              value: u.arg,
              done: n.done
            };
          }
          "throw" === u.type && (r = "completed", n.method = "throw", n.arg = u.arg);
        }
      };
    }
    function _(t, e) {
      var n = e.method,
        r = t.iterator[n];
      if (void 0 === r) return e.delegate = null, "throw" === n && t.iterator.return && (e.method = "return", e.arg = void 0, _(t, e), "throw" === e.method) || "return" !== n && (e.method = "throw", e.arg = new TypeError("The iterator does not provide a '" + n + "' method")), f;
      var o = s(r, t.iterator, e.arg);
      if ("throw" === o.type) return e.method = "throw", e.arg = o.arg, e.delegate = null, f;
      var i = o.arg;
      return i ? i.done ? (e[t.resultName] = i.value, e.next = t.nextLoc, "return" !== e.method && (e.method = "next", e.arg = void 0), e.delegate = null, f) : i : (e.method = "throw", e.arg = new TypeError("iterator result is not an object"), e.delegate = null, f);
    }
    function x(t) {
      var e = {
        tryLoc: t[0]
      };
      1 in t && (e.catchLoc = t[1]), 2 in t && (e.finallyLoc = t[2], e.afterLoc = t[3]), (this || _global).tryEntries.push(e);
    }
    function L(t) {
      var e = t.completion || {};
      e.type = "normal", delete e.arg, t.completion = e;
    }
    function O(t) {
      (this || _global).tryEntries = [{
        tryLoc: "root"
      }], t.forEach(x, this || _global), this.reset(!0);
    }
    function k(t) {
      if (t) {
        var e = t[i];
        if (e) return e.call(t);
        if ("function" == typeof t.next) return t;
        if (!isNaN(t.length)) {
          var r = -1,
            o = function e() {
              for (; ++r < t.length;) if (n.call(t, r)) return e.value = t[r], e.done = !1, e;
              return e.value = void 0, e.done = !0, e;
            };
          return o.next = o;
        }
      }
      return {
        next: j
      };
    }
    function j() {
      return {
        value: void 0,
        done: !0
      };
    }
    return d.prototype = p, r(b, "constructor", {
      value: p,
      configurable: !0
    }), r(p, "constructor", {
      value: d,
      configurable: !0
    }), d.displayName = u(p, c, "GeneratorFunction"), t.isGeneratorFunction = function (t) {
      var e = "function" == typeof t && t.constructor;
      return !!e && (e === d || "GeneratorFunction" === (e.displayName || e.name));
    }, t.mark = function (t) {
      return Object.setPrototypeOf ? Object.setPrototypeOf(t, p) : (t.__proto__ = p, u(t, c, "GeneratorFunction")), t.prototype = Object.create(b), t;
    }, t.awrap = function (t) {
      return {
        __await: t
      };
    }, m(w.prototype), u(w.prototype, a, function () {
      return this || _global;
    }), t.AsyncIterator = w, t.async = function (e, n, r, o, i) {
      void 0 === i && (i = Promise);
      var a = new w(l(e, n, r, o), i);
      return t.isGeneratorFunction(n) ? a : a.next().then(function (t) {
        return t.done ? t.value : a.next();
      });
    }, m(b), u(b, c, "Generator"), u(b, i, function () {
      return this || _global;
    }), u(b, "toString", function () {
      return "[object Generator]";
    }), t.keys = function (t) {
      var e = Object(t),
        n = [];
      for (var r in e) n.push(r);
      return n.reverse(), function t() {
        for (; n.length;) {
          var r = n.pop();
          if (r in e) return t.value = r, t.done = !1, t;
        }
        return t.done = !0, t;
      };
    }, t.values = k, O.prototype = {
      constructor: O,
      reset: function (t) {
        if ((this || _global).prev = 0, (this || _global).next = 0, (this || _global).sent = (this || _global)._sent = void 0, (this || _global).done = !1, (this || _global).delegate = null, (this || _global).method = "next", (this || _global).arg = void 0, (this || _global).tryEntries.forEach(L), !t) for (var e in this || _global) "t" === e.charAt(0) && n.call(this || _global, e) && !isNaN(+e.slice(1)) && ((this || _global)[e] = void 0);
      },
      stop: function () {
        (this || _global).done = !0;
        var t = (this || _global).tryEntries[0].completion;
        if ("throw" === t.type) throw t.arg;
        return (this || _global).rval;
      },
      dispatchException: function (t) {
        if ((this || _global).done) throw t;
        var e = this || _global;
        function r(n, r) {
          return a.type = "throw", a.arg = t, e.next = n, r && (e.method = "next", e.arg = void 0), !!r;
        }
        for (var o = (this || _global).tryEntries.length - 1; o >= 0; --o) {
          var i = (this || _global).tryEntries[o],
            a = i.completion;
          if ("root" === i.tryLoc) return r("end");
          if (i.tryLoc <= (this || _global).prev) {
            var c = n.call(i, "catchLoc"),
              u = n.call(i, "finallyLoc");
            if (c && u) {
              if ((this || _global).prev < i.catchLoc) return r(i.catchLoc, !0);
              if ((this || _global).prev < i.finallyLoc) return r(i.finallyLoc);
            } else if (c) {
              if ((this || _global).prev < i.catchLoc) return r(i.catchLoc, !0);
            } else {
              if (!u) throw new Error("try statement without catch or finally");
              if ((this || _global).prev < i.finallyLoc) return r(i.finallyLoc);
            }
          }
        }
      },
      abrupt: function (t, e) {
        for (var r = (this || _global).tryEntries.length - 1; r >= 0; --r) {
          var o = (this || _global).tryEntries[r];
          if (o.tryLoc <= (this || _global).prev && n.call(o, "finallyLoc") && (this || _global).prev < o.finallyLoc) {
            var i = o;
            break;
          }
        }
        i && ("break" === t || "continue" === t) && i.tryLoc <= e && e <= i.finallyLoc && (i = null);
        var a = i ? i.completion : {};
        return a.type = t, a.arg = e, i ? ((this || _global).method = "next", (this || _global).next = i.finallyLoc, f) : this.complete(a);
      },
      complete: function (t, e) {
        if ("throw" === t.type) throw t.arg;
        return "break" === t.type || "continue" === t.type ? (this || _global).next = t.arg : "return" === t.type ? ((this || _global).rval = (this || _global).arg = t.arg, (this || _global).method = "return", (this || _global).next = "end") : "normal" === t.type && e && ((this || _global).next = e), f;
      },
      finish: function (t) {
        for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
          var n = (this || _global).tryEntries[e];
          if (n.finallyLoc === t) return this.complete(n.completion, n.afterLoc), L(n), f;
        }
      },
      catch: function (t) {
        for (var e = (this || _global).tryEntries.length - 1; e >= 0; --e) {
          var n = (this || _global).tryEntries[e];
          if (n.tryLoc === t) {
            var r = n.completion;
            if ("throw" === r.type) {
              var o = r.arg;
              L(n);
            }
            return o;
          }
        }
        throw new Error("illegal catch attempt");
      },
      delegateYield: function (t, e, n) {
        return (this || _global).delegate = {
          iterator: k(t),
          resultName: e,
          nextLoc: n
        }, "next" === (this || _global).method && ((this || _global).arg = void 0), f;
      }
    }, t;
  }
  function At(t, e, n, r, o, i, a) {
    try {
      var c = t[i](a),
        u = c.value;
    } catch (t) {
      return void n(t);
    }
    c.done ? e(u) : Promise.resolve(u).then(r, o);
  }
  function Mt(t) {
    return function () {
      var e = this || _global,
        n = arguments;
      return new Promise(function (r, o) {
        var i = t.apply(e, n);
        function a(t) {
          At(i, r, o, a, c, "next", t);
        }
        function c(t) {
          At(i, r, o, a, c, "throw", t);
        }
        a(void 0);
      });
    };
  }
  function Dt(t, e) {
    for (var n = 0; n < e.length; n++) {
      var r = e[n];
      r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(t, (o = r.key, i = void 0, i = function (t, e) {
        if ("object" !== Pt(t) || null === t) return t;
        var n = t[Symbol.toPrimitive];
        if (void 0 !== n) {
          var r = n.call(t, e || "default");
          if ("object" !== Pt(r)) return r;
          throw new TypeError("@@toPrimitive must return a primitive value.");
        }
        return ("string" === e ? String : Number)(t);
      }(o, "string"), "symbol" === Pt(i) ? i : String(i)), r);
    }
    var o, i;
  }
  function Nt(t) {
    var e = Ft();
    return function () {
      var n,
        r = Gt(t);
      if (e) {
        var o = Gt(this || _global).constructor;
        n = Reflect.construct(r, arguments, o);
      } else n = r.apply(this || _global, arguments);
      return function (t, e) {
        if (e && ("object" === Pt(e) || "function" == typeof e)) return e;
        if (void 0 !== e) throw new TypeError("Derived constructors may only return object or undefined");
        return Rt(t);
      }(this || _global, n);
    };
  }
  function Rt(t) {
    if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return t;
  }
  function qt(t) {
    var e = "function" == typeof Map ? new Map() : void 0;
    return qt = function (t) {
      if (null === t || (n = t, -1 === Function.toString.call(n).indexOf("[native code]"))) return t;
      var n;
      if ("function" != typeof t) throw new TypeError("Super expression must either be null or a function");
      if (void 0 !== e) {
        if (e.has(t)) return e.get(t);
        e.set(t, r);
      }
      function r() {
        return Bt(t, arguments, Gt(this || _global).constructor);
      }
      return r.prototype = Object.create(t.prototype, {
        constructor: {
          value: r,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), Ht(r, t);
    }, qt(t);
  }
  function Bt(t, e, n) {
    return Bt = Ft() ? Reflect.construct.bind() : function (t, e, n) {
      var r = [null];
      r.push.apply(r, e);
      var o = new (Function.bind.apply(t, r))();
      return n && Ht(o, n.prototype), o;
    }, Bt.apply(null, arguments);
  }
  function Ft() {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
    } catch (t) {
      return !1;
    }
  }
  function Ht(t, e) {
    return Ht = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (t, e) {
      return t.__proto__ = e, t;
    }, Ht(t, e);
  }
  function Gt(t) {
    return Gt = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (t) {
      return t.__proto__ || Object.getPrototypeOf(t);
    }, Gt(t);
  }
  function It(t, e) {
    if (e.has(t)) throw new TypeError("Cannot initialize the same private elements twice on an object");
  }
  function Wt(t, e) {
    var n = function (t, e, n) {
      if (!e.has(t)) throw new TypeError("attempted to " + n + " private field on non-instance");
      return e.get(t);
    }(t, e, "get");
    return function (t, e) {
      if (e.get) return e.get.call(t);
      return e.value;
    }(t, n);
  }
  function Yt(t, e, n) {
    if (!e.has(t)) throw new TypeError("attempted to get private field on non-instance");
    return n;
  }
  var Vt = new WeakSet(),
    zt = new WeakMap(),
    Zt = function (t) {
      !function (t, e) {
        if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
        t.prototype = Object.create(e && e.prototype, {
          constructor: {
            value: t,
            writable: !0,
            configurable: !0
          }
        }), Object.defineProperty(t, "prototype", {
          writable: !1
        }), e && Ht(t, e);
      }(c, t);
      var e,
        n,
        r,
        o,
        i,
        a = Nt(c);
      function c() {
        var t, e, n;
        !function (t, e) {
          if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, c);
        for (var r = arguments.length, o = new Array(r), i = 0; i < r; i++) o[i] = arguments[i];
        return t = a.call.apply(a, [this || _global].concat(o)), It(e = Rt(t), n = Vt), n.add(e), function (t, e, n) {
          It(t, e), e.set(t, n);
        }(Rt(t), zt, {
          writable: !0,
          value: function (e) {
            switch (e.data.type) {
              case "embed-request-cancelled":
                t.dispatchEvent(new CustomEvent("cronofyschedulerrequestcancelled", {
                  detail: e.data.content
                })), t.close();
                break;
              case "embed-close-dialog":
                t.close();
            }
          }
        }), t;
      }
      return e = c, n = [{
        key: "open",
        get: function () {
          return (this || _global)._cancelDialog.open;
        }
      }, {
        key: "connectedCallback",
        value: (i = Mt(Tt().mark(function t() {
          var e,
            n = this || _global;
          return Tt().wrap(function (t) {
            for (;;) switch (t.prev = t.next) {
              case 0:
                (e = this.attachShadow({
                  mode: "closed"
                })).innerHTML = "\n            <style>\n                dialog {\n                    display: flex;\n                    flex-direction: column;\n                    width: 450px;\n                    height: 450px;\n                }\n\n                dialog:modal {\n                    border: none;\n                    border-radius: 6px 6px 6px 6px;\n                    padding: 0;\n                }\n\n                .content {\n                    flex-grow: 1;\n                    border: none;\n                    margin: 0;\n                    padding: 0;\n                }\n\n                .centered-content {\n                    display: flex;\n                    flex-grow: 1;\n                    flex-direction: column;\n                    margin: 0;\n                    padding: 0;\n                    justify-content: center;\n                    align-items: center;\n                }\n            </style>\n\n            <dialog part=\"dialog\"></dialog>\n        ", (this || _global)._cancelDialog = e.querySelector("dialog"), (this || _global)._cancelDialog.addEventListener("click", function (t) {
                  if (t.target === n._cancelDialog) {
                    var e = n._cancelDialog.getBoundingClientRect();
                    e.top <= t.clientY && t.clientY <= e.top + e.height && e.left <= t.clientX && t.clientX <= e.left + e.width || n.close();
                  }
                }), (this || _global)._cancelDialog.addEventListener("close", function (t) {
                  Yt(n, Vt, Xt).call(n);
                });
              case 5:
              case "end":
                return t.stop();
            }
          }, t, this || _global);
        })), function () {
          return i.apply(this || _global, arguments);
        })
      }, {
        key: "disconnectedCallback",
        value: function () {
          this.close();
        }
      }, {
        key: "show",
        value: (o = Mt(Tt().mark(function t() {
          var e,
            n,
            r,
            o = this || _global;
          return Tt().wrap(function (t) {
            for (;;) switch (t.prev = t.next) {
              case 0:
                return (this || _global)._cancelDialog.innerHTML = "\n            <div class=\"centered-content\">\n                <p>\n                    Loading...\n                </p>\n            </div>\n        ", (this || _global)._cancelDialog.showModal(), this.dispatchEvent(new Event("cronofyschedulerdialogopened")), t.prev = 3, e = this.getAttribute("embed-token"), n = this.getAttribute("scheduling-request-id"), t.next = 8, d(e, {
                  embed_action: "cancel",
                  scheduling_request_id: n
                });
              case 8:
                r = t.sent, (this || _global)._cancelDialog.innerHTML = "\n                <iframe\n                    class=\"content\"\n                    src=\"".concat(r, "\"\n                    title=\"Cancel Cronofy Scheduling Request\"\n                ></iframe>\n            "), window.addEventListener("message", Wt(this || _global, zt)), t.next = 20;
                break;
              case 13:
                t.prev = 13, t.t0 = t.catch(3), console.error("An unexpected error occurred while trying to show the cancel scheduling request dialog.\n", t.t0, this || _global), (this || _global)._cancelDialog.innerHTML = "\n                <div class=\"centered-content\">\n                    <p>\n                        An error occurred.\n                        See console for details.\n                    </p>\n                    <button>Close</button>\n                </div>\n            ", (this || _global)._cancelDialog.querySelector("div > button").addEventListener("click", function (t) {
                  o.close();
                }), this.dispatchEvent(new CustomEvent("cronofyschedulererror", {
                  detail: {
                    code: "unknown",
                    exception: t.t0
                  }
                }));
              case 20:
              case "end":
                return t.stop();
            }
          }, t, this || _global, [[3, 13]]);
        })), function () {
          return o.apply(this || _global, arguments);
        })
      }, {
        key: "close",
        value: function () {
          (this || _global)._cancelDialog.open && (this || _global)._cancelDialog.close();
        }
      }], n && Dt(e.prototype, n), r && Dt(e, r), Object.defineProperty(e, "prototype", {
        writable: !1
      }), c;
    }(qt(HTMLElement));
  function Xt() {
    window.removeEventListener("message", Wt(this || _global, zt)), this.dispatchEvent(new Event("cronofyschedulerdialogclosed"));
  }
  window.customElements.define("cronofy-scheduler-dialog", D), window.customElements.define("cronofy-scheduler-button", ut), window.customElements.define("cronofy-scheduler-cancel-button", kt), window.customElements.define("cronofy-scheduler-cancel-dialog", Zt), CronofySchedulerEmbed = e;
})();
export default {};